import stylesLight from './detailed-note.module.css';
import stylesDark from './detailed-note-dark.module.css';
import {useNavigate, useParams} from 'react-router-dom';
import {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import catImage from "../../images/cat.jpg";
import {useDispatch, useSelector} from "react-redux";
import {getNote} from "../../services/actions/get-note";
import {saveNote} from "../../services/actions/save-note";
import {unSaveNote} from "../../services/actions/unsave-note";
import {rateNote} from "../../services/actions/rate-note";
import Skeleton from "@mui/material/Skeleton";
import {getNoteNoAuth} from "../../services/actions/noauth/get-note-noauth";
import {deleteNote} from "../../services/actions/delete-note";
import {Bounce, toast} from "react-toastify";

function DetailedNote() {
    const theme = useSelector(state => {
        return state.theme
    })
    const navigate = useNavigate();
    const handleDeleteItem = (id) => {
        deleteNote(id)
            .then(() => {
                toast.success(`Конспект удален успешно`, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce,
                });
                navigate(-1); 
            })
            .catch((err) => {
                toast.error(`${err}`, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce,
                });
            });
    };

    const [styles, setStyles] = useState(theme === 'light' ? stylesLight : stylesDark)
    useEffect(() => {
        setStyles(theme === 'light' ? stylesLight : stylesDark)
    }, [theme]);
    let { id } = useParams();
    const dispatch = useDispatch();
    const profileId = useSelector(state => {
        return state.id
    })
    useEffect(()=> {
        if (profileId) {
            dispatch(getNote(id));
        }
        else if (!localStorage.hasOwnProperty("user")) {
            dispatch(getNoteNoAuth(id))
        }
    },[dispatch, id, profileId]);
    const note = useSelector(state => {
        return state.note
    });
    const [stars, setStars] = useState(0);
    return (
        <section className={styles.main}>
            <div className={styles.album}>
                {
                    note.image === null ? (
                            <Skeleton width={"100%"} animation="wave" variant={"rounded"}>
                                <img src={catImage} className={styles.image} alt={"Конспект"}/>
                            </Skeleton>
                        )
                        :
                        <img id={'myImage'} className={styles.image} alt={"Конспект"} src={note.image}/>
                }
            </div>
            <div className={styles.note}>
            <div className={styles.rating}>
                {
                    note.name === null ? (
                            <Skeleton animation="wave" variant={"rounded"}>
                                <p className={styles.title}>
                                    anastasiaaaaaaaaaaaaaaaaaaa
                                </p>
                            </Skeleton>
                        )
                        :
                        <p className={styles.title}>
                            {note.name}
                        </p>
                }
                {
                    profileId && note.owner_id !== null && String(profileId) === String(note.owner_id) &&
                    <button onClick={() => handleDeleteItem(id)} className={styles.delete__button}>
                        Удалить
                    </button>

                }
            </div>
                {
                    note.description === null ? (
                            <Skeleton animation="wave" variant={"rounded"}>
                                <p className={styles.description}>
                                    afduhjdfa  jiuhfayg aukdhfuy dgajdyug n auidfgb  uagf asg fydagf
                                </p>
                            </Skeleton>
                        )
                        :
                        <p className={styles.description}>
                            {note.description}
                        </p>
                }
                {
                    note.rating === null ? (
                            <Skeleton animation="wave" variant={"rounded"}>
                                <p className={styles.rating__text}>
                                    19888927
                                </p>
                            </Skeleton>
                        )
                        :
                        <p className={styles.rating__text}>
                            {note.rating} ⭐
                        </p>
                }
                {
                    !note.date ? (
                            <Skeleton animation="wave" variant={"rounded"}>
                                <i className={styles.data}>
                                    18.29.2038
                                </i>
                            </Skeleton>
                        )
                        :
                        <i className={styles.data}>
                            {note.date}
                        </i>
                }
                <Link className={styles.profile} to={`/profile/${note.owner_id}`}>
                    {
                        note.owner_id === null ? (
                                <Skeleton animation="wave" variant={"circular"}>
                                    <img className={styles.avatar}
                                         src={"https://api.dicebear.com/8.x/thumbs/svg?seed=" + note.owner_id}
                                         alt={"Avatar"}/>
                                </Skeleton>
                            )
                            :
                            <img className={styles.avatar}
                                 src={"https://api.dicebear.com/8.x/thumbs/svg?seed=" + note.owner_id} alt={"Avatar"}/>
                    }
                    {
                        note.owner_name === null ? (
                                <Skeleton animation="wave" variant={"rounded"}>
                                    <p className={styles.name}>Anastasia</p>
                                </Skeleton>
                            )
                            :
                            <p className={styles.name}>{note.owner_name}</p>
                    }
                </Link>
                {profileId && note.owner_id !== null && String(profileId) !== String(note.owner_id) && (note.stars === null ?
                    <form onSubmit={(event) => {event.preventDefault();dispatch(rateNote(id, stars))}} className={styles.stars}>
                        <div className={styles.star__group}>
                            <input onClick={() => setStars(1)} type="radio" className={styles.star} id="one" name="star_rating"/>
                            <input onClick={() => setStars(2)} type="radio" className={styles.star} id="two" name="star_rating"/>
                            <input onClick={() => setStars(3)} type="radio" className={styles.star} id="three" name="star_rating"/>
                            <input onClick={() => setStars(4)} type="radio" className={styles.star} id="four" name="star_rating"/>
                            <input onClick={() => setStars(5)} type="radio" className={styles.star} id="five" name="star_rating"/>
                        </div>
                        <button className={styles.rating__button}>
                            Оценить
                        </button>
                    </form> :
                    <div className={styles.stars}>
                        <div className={styles.star__group}>
                            <p className={Number(note.stars) >= 1 ? styles.p_star : styles.star}>
                            </p>
                            <p className={Number(note.stars) >= 2 ? styles.p_star : styles.star}>
                            </p>
                            <p className={Number(note.stars) >= 3 ? styles.p_star : styles.star}>
                            </p>
                            <p className={Number(note.stars) >= 4 ? styles.p_star : styles.star}>
                            </p>
                            <p className={Number(note.stars) === 5 ? styles.p_star : styles.star}>
                            </p>
                        </div>
                        <button className={styles.rating__button}>
                            Оценено
                        </button>
                    </div>)
                }
            </div>
            <div className={styles.buttons}>
                {
                    profileId && note.owner_id !== null && String(profileId) !== String(note.owner_id) && (note.is_saved ?
                        <button onClick={() => dispatch(unSaveNote(id))} className={styles.save_button}>
                            Убрать из сохраненных
                        </button>
                        :
                        <button onClick={() => dispatch(saveNote(id))} className={styles.save_button}>
                            Сохранить
                        </button>)

                }
                <button download={note.name} target="_blank" className={styles.button}>
                    <a className={styles.link} href={note.url} target="_blank">Скачать файл</a>
                </button>
            </div>
        </section>
    );
}

export default DetailedNote;
