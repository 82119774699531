import {checkResponse} from "../utils";
import {fileServiceBaseUrl} from "../consts";
export function deleteNote(id) {
    return fetch(`${fileServiceBaseUrl}/note/delete/${id}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': "application/json",
            'Authorization': `${localStorage.getItem("token")}`
        }
    })
        .then((res) => checkResponse(res))
}
