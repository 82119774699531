import {checkResponse} from "../utils";
import {SAVE_NOTE} from "./index";
import {Bounce, toast} from "react-toastify";
import {fileServiceBaseUrl} from "../consts";
export function saveNote(id) {
    return function (dispatch) {
        fetch(`${fileServiceBaseUrl}/note/save/${id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': "application/json",
                'Authorization': `${localStorage.getItem("token")}`
            }
        })
            .then((res) => checkResponse(res))
            .then(() => {
                dispatch({
                    type: SAVE_NOTE
                })
            })
            .then(() => {
                toast.success("Конспект успешно сохранен", {
                    position: "top-right",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce,
                });
            })
            .catch((error) => {
                console.log(error)
                toast.error(error, {
                    position: "top-right",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce,
                });
            })
    }
}
