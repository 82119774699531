import {SEARCH} from "./index";
import {checkResponse} from "../utils";
import {fileServiceBaseUrl} from "../consts";
export function getDefaultNotes(page, words, flag) {
    return function (dispatch) {
        fetch(`${fileServiceBaseUrl}/catalog/${page - 1}/popular`, {
            method: 'GET',
            headers: {
                'Content-Type': "application/json",
                'Authorization': `${localStorage.getItem("token")}`
            }
        })
            .then((res) => checkResponse(res))
            .then((res) => {
                dispatch({
                    type: SEARCH,
                    notes: res.payload.filesInfo,
                    page: page,
                    words: words,
                    flag: flag
                })
            })
            .catch(console.error)
    }
}
