import {Link, useNavigate} from "react-router-dom";
import {Bounce, toast} from "react-toastify";
import {useEffect, useState} from "react";
import {signIn} from "../../services/actions/sign-in";
import {useDispatch, useSelector} from "react-redux";
import {ID} from "../../services/actions";
import stylesLight from "./entry.module.css"
import stylesDark from "./entry-dark.module.css"
function Entry() {
    const [email, setEmail] = useState('');
    const navigate = useNavigate();
    const [password, setPassword] = useState('');
    const dispatch = useDispatch();
    const theme = useSelector(state => {
        return state.theme
    })
    const [styles, setStyles] = useState(theme === 'light' ? stylesLight : stylesDark)
    useEffect(() => {
        setStyles(theme === 'light' ? stylesLight : stylesDark)
    }, [theme]);
    const entry = (event) => {
        event.preventDefault();
        signIn(email, password)
            .then(() => {
                toast.success("Успешный вход", {
                    position: "top-right",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce,
                });
                navigate("/search");
            })
            .then(() => {
                dispatch({
                    type: ID,
                    id: localStorage.getItem("user"),
                })
            })
            .catch((error) => {
                console.log(error);
                toast.error(error, {
                    position: "top-right",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce,
                })
                navigate("/entry");
            })
    }
    return(
        <>
            <section className={styles.main}>
                <fieldset id={"entry-form"} className={styles.form}>
                    <h2 className={styles.title}>
                        Вход
                    </h2>
                    <form onSubmit={(event) => entry(event)} className={styles.input__container}>
                        <input className={styles.input} type="email" required id="email"
                               placeholder="E-mail" onChange={(event) => (
                            setEmail(event.target.value)
                        )}/>
                        <input className={styles.input} type="password" required id="password"
                               placeholder="Пароль" onChange={(event) => (
                            setPassword(event.target.value)
                        )}/>
                        <button className={styles.button} type="submit">
                            Войти
                        </button>
                    </form>
                </fieldset>
                <div className={styles.text}>
                    <div className={styles.text__container}>
                        <p className={styles.subtitle}>
                            Вы новый пользователь?
                        </p>
                        <Link to={'/signup'} className={styles.link}>
                            Зарегистрироваться
                        </Link>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Entry;