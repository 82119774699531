import stylesLight from "./landing.module.css"
import stylesDark from "./landing-dark.module.css"
import image1 from "./../../images/image1.png"
import image2 from "./../../images/image2.jpg"
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {ID} from "../../services/actions";
import {useEffect, useState} from "react";
function Landing() {
    const theme = useSelector(state => {
        return state.theme
    })
    const dispatch = useDispatch();
    const exit = () => {
        dispatch({
            type: ID,
            id: null
        })
        localStorage.removeItem("token");
        localStorage.removeItem("user");
    }
    const [styles, setStyles] = useState(theme === 'light' ? stylesLight : stylesDark)
    useEffect(() => {
        setStyles(theme === 'light' ? stylesLight : stylesDark)
    }, [theme]);
    const profileId = useSelector(state => {
        return state.id
    })
    return(
        <section className={styles.main}>
            {
                theme === 'light' &&
                <img className={styles.image1} alt={"Картинка 1"} src={image1}/>
            }
            {theme === 'light' && <img className={styles.image2} alt={"Картика 2"} src={image2}/>}
            <h1 className={styles.title}>
                Все конспекты под рукой -
            </h1>
            <h1 className={styles.title}>
                 NoteShare
            </h1>
            {profileId === null ? <div className={styles.buttons}>
                    <Link to={'/entry'}>
                        <button className={styles.entry}>
                            Вход
                        </button>
                    </Link>
                    <Link to={'/signup'}>
                        <button className={styles.signup}>
                            Регистрация
                        </button>
                    </Link>
                </div> :
                <button onClick={exit} className={styles.entry}>
                    Выход
                </button>
            }
        </section>
    )
}

export default Landing;