import {PROFILE} from "./index";
import {checkResponse} from "../utils";
import {fileServiceBaseUrl} from "../consts";
export function getProfileById(id) {
    return function (dispatch) {
        fetch(`${fileServiceBaseUrl}/profile/${id}`, {
            method: 'GET',
            headers: {
                'Content-Type': "application/json",
                'Authorization': `${localStorage.getItem("token")}`
            }
        })
            .then((res) => checkResponse(res))
            .then((res) => {
                dispatch({
                    type: PROFILE,
                    name: res.payload.username,
                    rating: res.payload.rating,
                    sub_number: res.payload.subscribersCount,
                    publications_number: res.payload.notesAddedCount,
                    is_sub: res.payload.isSubscribedByUser,
                })
            })
            .catch(console.error)
    }
}
