import {PROFILE_SAVED_NOTES} from "./index";
import {checkResponse} from "../utils";
import {fileServiceBaseUrl} from "../consts";
export function getSavedNotes(id, page) {
    return function (dispatch) {
        fetch(`${fileServiceBaseUrl}/profile/${id}/notes/saved/${page - 1}`, {
            method: 'GET',
            headers: {
                'Content-Type': "application/json",
                'Authorization': `${localStorage.getItem("token")}`
            }
        })
            .then((res) => checkResponse(res))
            .then((res) => {
                dispatch({
                    type: PROFILE_SAVED_NOTES,
                    saved_notes: res.payload.filesInfo,
                    saved_notes_page: page,
                })
            })
            .catch(console.error)
    }
}
