import stylesLight from "./profile.module.css";
import stylesDark from "./profile-dark.module.css"
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import addButton from "./../../images/Add Button.png"
import pagination from "./../../images/pagination.png"
import paginationDark from "./../../images/pagination-dark.png"
import NoteCard from "../note-card/note-card";
import Skeleton from '@mui/material/Skeleton';
import {Link, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {getProfileById} from "../../services/actions/get_profile-by-id";
import ProfileCard from "../profile-card/profile-card";
import {getSavedNotes} from "../../services/actions/get-saved-notes";
import {getCreatedNotes} from "../../services/actions/get-created-notes";
import {getSubscriptions} from "../../services/actions/get-subscriptions";
import {subscribe} from "../../services/actions/subscribe";
import {unSubscribe} from "../../services/actions/unsubscribe";

import { jelly } from 'ldrs';
import {getProfileByIdNoAuth} from "../../services/actions/noauth/get-profile-by-id-noauth";
import {getCreatedNotesNoAuth} from "../../services/actions/noauth/get-created-notes-noauth";

jelly.register()

function Profile() {
    let { id } = useParams();
    const profileId = useSelector(state => {
        return state.id
    })
    const dispatch = useDispatch();
    useEffect(()=> {
        if (profileId) {
            dispatch(getProfileById(id));
        }
        else if (!localStorage.hasOwnProperty("user")) {
            dispatch(getProfileByIdNoAuth(id))
        }
    },[dispatch, id, profileId]);
    const handleGetCreatedNotes = (id, page) => {
        if (profileId) {
            dispatch(getCreatedNotes(id, page));
        }
        else if (!localStorage.hasOwnProperty("user")){
            dispatch(getCreatedNotesNoAuth(id, 1))
        }
    }
    useEffect(()=>{
        handleGetCreatedNotes(id, 1);
    },[dispatch, id, profileId]);
    useEffect(()=>{
        if (profileId) {
            dispatch(getSavedNotes(id, 1));
        }
    },[dispatch, id, profileId]);
    useEffect(()=>{
        if (profileId) {
            dispatch(getSubscriptions(id));
        }
    },[dispatch, id, profileId]);
    const theme = useSelector(state => {
        return state.theme
    })
    const [styles, setStyles] = useState(theme === 'light' ? stylesLight : stylesDark)
    useEffect(() => {
        setStyles(theme === 'light' ? stylesLight : stylesDark)
    }, [theme]);
    const profile = useSelector(state => {
        return state.profile
    });
    return(
        <section className={styles.profile}>
            {profile.name === null ?
                (<Skeleton animation="wave" variant={"rounded"}>
                    <img src={"https://api.dicebear.com/8.x/thumbs/svg?seed=" + id} alt="Аватар"
                         className={styles.avatar}/>
                </Skeleton>)
                :
                <img src={"https://api.dicebear.com/8.x/thumbs/svg?seed=" + id} alt="Аватар" className={styles.avatar}/>
            }
            {
                profile.name === null ? (
                        <Skeleton animation="wave" variant={"rounded"}>
                            <p className={styles.name}>
                                @shulepupsik
                            </p>
                        </Skeleton>
                    )
                    :
                    <p className={styles.name}>
                        {'@' + profile.name}
                    </p>
            }
            <div className={styles.table}>
                <div className={styles.text}>
                    <p className={styles.title}>
                        Рейтинг
                    </p>
                    {
                        profile.rating === null ? (
                                <Skeleton animation="wave" variant={"rounded"}>
                                    <p className={styles.subtitle}>
                                       rating
                                    </p>
                                </Skeleton>
                            )
                            :
                            <p className={styles.subtitle}>
                                {profile.rating.toFixed(2)}⭐
                            </p>
                    }
                </div>
                <div className={styles.text}>
                <p className={styles.title}>
                        Подписчики
                    </p>
                    {
                        profile.sub_number === null ? (
                                <Skeleton animation="wave" variant={"rounded"}>
                                    <p className={styles.subtitle}>
                                        number
                                    </p>
                                </Skeleton>
                            )
                            :
                            <p className={styles.subtitle}>
                                {profile.sub_number}
                            </p>
                    }
                </div>
                <div className={styles.text}>
                <p className={styles.title}>
                        Публикации
                    </p>
                    {
                        profile.publications_number === null ? (
                                <Skeleton animation="wave" variant={"rounded"}>
                                    <p className={styles.subtitle}>
                                        number
                                    </p>
                                </Skeleton>
                            )
                            :
                            <p className={styles.subtitle}>
                                {profile.publications_number}
                            </p>
                    }
                </div>
            </div>
            {id === profileId &&
                <Link to={"/add-note"}>
                    <button className={styles.add__button} type="submit">
                        <img src={addButton} alt={"добавить"} className={styles.button__image}/>
                    </button>
                </Link>}
            {profileId && id !== profileId && (
                profile.is_sub === null ? (
                    <Skeleton sx={{ bgcolor: '#BB86FC' }} animation="wave" borderradius={125} variant={"rounded"}>
                        <button onClick={() => dispatch(unSubscribe(id))} className={styles.sub__button} type="submit">
                            Отписаться
                        </button>
                    </Skeleton>
                ) : (
                    profile.is_sub ? (
                        <button onClick={() => dispatch(unSubscribe(id))} className={styles.sub__button} type="submit">
                            Отписаться
                        </button>
                    ) : (
                        <button onClick={() => dispatch(subscribe(id))} className={styles.unsub__button} type="submit">
                            Подписаться
                        </button>
                    )
                )
            )}
            <Tabs
                defaultActiveKey="created"
                id="profile-tabs"
                className={styles.tabs}
            >
                <Tab tabClassName={theme === 'dark' && styles["tab-active"]} eventKey="created" title="Созданные">
                    {profile.created_notes === null ?
                        (<l-jelly
                            size="40"
                            speed="0.9"
                            color="#99C1F1"
                        ></l-jelly>) :
                        (<div className={styles.cards__list}>
                            {profile.created_notes.length > 0 && profile.created_notes.map((note) => (
                                <NoteCard isPublic={note.public} key={note._id} flag={true} {...note}/>))}
                        </div>)
                    }
                    <div className={styles.pagination__buttons}>
                        {profile.created_notes !== null && profile.created_notes_page !== 1 &&
                            <button onClick={() => handleGetCreatedNotes(id, profile.created_notes_page - 1)}
                                    className={styles.pagination__button__left}>
                                <img alt={"Предыдущие 20 конспектов"} className={styles.pagination__image}
                                     src={theme === 'light' ? pagination : paginationDark}/>
                            </button>
                        }
                        { profile.created_notes !== null &&
                            profile.created_notes.length === 20 &&
                            <button onClick={() => handleGetCreatedNotes(id, profile.created_notes_page + 1)}
                                    className={styles.pagination__button__right}>
                                <img alt={"Следующие 20 конспектов"} className={styles.pagination__image}
                                     src={theme === 'light' ? pagination : paginationDark}/>
                            </button>
                        }
                    </div>
                </Tab>
                {profileId &&
                    <Tab tabClassName={styles["tab-active"]} eventKey="saved" title="Сохраненные">
                        <div className={styles.cards__list}>
                            {profile.saved_notes !== null && profile.saved_notes.length > 0 && profile.saved_notes.map((note) => (
                                <NoteCard isPublic={note.public} key={note._id} flag={true} {...note}/>))}
                        </div>
                        <div className={styles.pagination__buttons}>
                            {profile.saved_notes !== null && profile.saved_notes_page !== 1 &&
                                <button onClick={() => dispatch(getSavedNotes(id, profile.saved_notes_page - 1))} className={styles.pagination__button__left}>
                                    <img alt={"Предыдущие 20 конспектов"} className={styles.pagination__image}
                                         src={theme === 'light' ? pagination : paginationDark}/>
                                </button>
                            }
                            {profile.saved_notes !== null &&
                                profile.saved_notes.length === 20 &&
                                <button onClick={() => dispatch(getSavedNotes(id, profile.saved_notes_page + 1))}
                                        className={styles.pagination__button__right}>
                                    <img alt={"Следующие 20 конспектов"} className={styles.pagination__image}
                                         src={theme === 'light' ? pagination : paginationDark}/>
                                </button>
                            }
                        </div>
                    </Tab>
                }
                {profileId && <Tab tabClassName={styles["tab-active"]} eventKey="subscriptions" title="Подписки">
                    <div className={styles.cards__list}>
                        {profile.subscriptions !== null && profile.subscriptions.length > 0 && profile.subscriptions.map((user) => (
                            <ProfileCard key={user.id} {...user}/>))}
                    </div>
                </Tab>}

            </Tabs>
            <hr className={styles.line}>
            </hr>
        </section>
    )
}

export default Profile;
