import {PROFILE} from "../index";
import {checkResponse} from "../../utils";
import {fileServiceBaseUrl} from "../../consts";
export function getProfileByIdNoAuth(id) {
    return function (dispatch) {
        fetch(`${fileServiceBaseUrl}/noauth/profile/${id}`, {
            method: 'GET',
            headers: {
                'Content-Type': "application/json"
            }
        })
            .then((res) => checkResponse(res))
            .then((res) => {
                dispatch({
                    type: PROFILE,
                    name: res.payload.username,
                    rating: res.payload.rating,
                    sub_number: res.payload.subscribersCount,
                    publications_number: res.payload.notesAddedCount,
                    is_sub: res.payload.isSubscribedByUser,
                })
            })
            .catch(console.error)
    }
}
