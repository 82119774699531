export const initialState = {
    id: null,
    avatar: null,
    profile: {
        name: null,
        rating: null,
        sub_number: null,
        publications_number: null,
        created_notes: null,
        saved_notes: [],
        subscriptions: null,
        created_notes_page: null,
        saved_notes_page: null,
        is_sub: null,
    },
    note: {
        id: null,
        name: null,
        description: null,
        owner_name: null,
        owner_id: null,
        is_saved: null,
        url: null,
        files: null,
        rating: null,
        stars: null,
        date: null,
        image: null,
    },
    search: {
        notes: null,
        page: null,
        words: null,
        flag: true
    },
    theme: 'light'
}