import stylesLight from "./signup.module.css";
import stylesDark from "./signup-dark.module.css";
import {Link, useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {toast, Bounce} from 'react-toastify';
import {signUp} from "../../services/actions/sign-up";
import 'react-toastify/dist/ReactToastify.css';
import {useSelector} from "react-redux";
function SignUp() {
    const theme = useSelector(state => {
        return state.theme
    })
    const navigate = useNavigate();
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password1, setPassword1] = useState('');
    const [password2, setPassword2] = useState('');
    const [captchaToken, setCaptchaToken] = useState('')
    const [styles, setStyles] = useState(theme === 'light' ? stylesLight : stylesDark)
    useEffect(() => {
        setStyles(theme === 'light' ? stylesLight : stylesDark)
    }, [theme]);
    const handleLoaded = _ => {
        window.grecaptcha.ready(_ => {
            window.grecaptcha
                .execute("6LfpprQpAAAAAIxRI00xkUEgGk7OorF1QM3H37z5", { action: "signup" })
                .then(token => {
                    console.log("ставлю капчу")
                    setCaptchaToken(token)
                })
        })
    }
    useEffect(() => {
        // Add reCaptcha
        const script = document.createElement("script")
        script.src = "https://www.google.com/recaptcha/api.js?render=6LfpprQpAAAAAIxRI00xkUEgGk7OorF1QM3H37z5"
        script.addEventListener("load", handleLoaded)
        document.body.appendChild(script)
    }, [])
    const sign = (event) => {
        event.preventDefault();
        if (!name.match(/^[a-z0-9_.,'"!?;:& ]+$/i)) {
            toast.error('Необходимо использование латинских букв', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
            });
            return;
        }
        if (password1 !== password2) {
            toast.error('Пароли не совпадают', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
            });
            return;
        }
        signUp(name, email, password1, captchaToken)
            .then(() => {
                toast.success("Успешная регистрация", {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce,
                });
            })
            .catch((error) => {
                console.log(error);
                toast.error(`${error}`, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce,
                })
            })
            .finally(() =>  navigate('/entry'))
    }
    return(
        <>
            <div
                className="g-recaptcha"
                data-sitekey="6LfpprQpAAAAAIxRI00xkUEgGk7OorF1QM3H37z5"
                data-size="invisible"
            ></div>
            <section className={styles.main}>
                <fieldset id={"entry-form"} className={styles.form}>
                    <h2 className={styles.title}>
                        Регистрация
                    </h2>
                    <form className={styles.input__container} onSubmit={(event) => (sign(event))}>
                        <input className={styles.input} type="text" required id="name"
                               placeholder="Никнейм" onChange={(event) => (
                            setName(event.target.value)
                        )}/>
                        <input className={styles.input} type="email" required id="email"
                               placeholder="E-mail" onChange={(event) => (setEmail(event.target.value))}/>
                        <input className={styles.input} type="password" required id="password"
                               placeholder="Пароль" onChange={(event) => (setPassword1(event.target.value))}/>
                        <input className={styles.input} type="password" required id="password"
                               placeholder="Повторите пароль" onChange={(event) => (setPassword2(event.target.value))}/>
                        <button className={styles.button} type="submit">
                            Зарегистрироваться
                        </button>
                    </form>
                </fieldset>
                <div className={styles.text}>
                    <div className={styles.text__container}>
                        <p className={styles.subtitle}>
                            Уже зарегистрированы?
                        </p>
                        <Link to={'/entry'} className={styles.link}>
                            Войти
                        </Link>
                    </div>
                </div>
            </section>
        </>
    )
}

export default SignUp;