import {PROFILE_CREATED_NOTES} from "../index";
import {checkResponse} from "../../utils";
import {fileServiceBaseUrl} from "../../consts";
export function getCreatedNotesNoAuth(id, page) {
    return function (dispatch) {
        fetch(`${fileServiceBaseUrl}/noauth/profile/${id}/notes/created/${page - 1}`, {
            method: 'GET',
            headers: {
                'Content-Type': "application/json"
            }
        })
            .then((res) => checkResponse(res))
            .then((res) => {
                dispatch({
                    type: PROFILE_CREATED_NOTES,
                    created_notes: res.payload.filesInfo,
                    created_notes_page: page,
                })
            })
            .catch(console.error)
    }
}
