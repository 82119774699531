import {CURRENT_NOTE} from "../index";
import {checkResponse} from "../../utils";
import {fileServiceBaseUrl} from "../../consts";
export function getNoteNoAuth(id) {
    return function (dispatch) {
        fetch(`${fileServiceBaseUrl}/noauth/note/${id}`, {
            method: 'GET',
            headers: {
                'Content-Type': "application/json"
            }
        })
            .then((res) => checkResponse(res))
            .then((res) => {
                dispatch({
                    type: CURRENT_NOTE,
                    id: res.payload.id,
                    name: res.payload.name,
                    description: res.payload.description,
                    owner_name: res.payload.user.username,
                    owner_id: res.payload.user.id,
                    is_saved: res.payload.isSaved,
                    image: res.payload.previewUrl,
                    url: res.payload.s3Url,
                    rating: res.payload.rating,
                    stars: res.payload.ratedByUser,
                    date: res.payload.creationTime
                })
            })
            .catch(console.error)
    }
}
