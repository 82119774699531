import stylesLight from "./filter.module.css";
import stylesDark from "./filter-dark.module.css"
import openIcon from "./../../images/open-filter.png";
import openFilterDark from "./../../images/open-filter-dark.png";
import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
function Filter({title, children}) {
    const [isOpen, open] = useState(false);
    const theme = useSelector(state => {
        return state.theme
    })
    const [styles, setStyles] = useState(theme === 'light' ? stylesLight : stylesDark)
    useEffect(() => {
        setStyles(theme === 'light' ? stylesLight : stylesDark)
    }, [theme]);
    return(
        <section className={styles.filter}>
            <button className={styles.button} onClick={() => open(!isOpen)}>
                <h2 className={styles.title}>
                    {title}
                </h2>
                <img className={!isOpen ? styles.image : styles.image__open} src={theme === 'light' ? openIcon : openFilterDark} alt={'Открыть фильтры'}/>
            </button>
            <div className={!isOpen ? styles.container : styles.container__open}>
                {children}
            </div>
        </section>
    )
}

export default Filter;