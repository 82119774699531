import {
    PROFILE_SAVED_NOTES,
    PROFILE,
    PROFILE_CREATED_NOTES,
    CURRENT_NOTE,
    AVATAR,
    SEARCH,
    ADD_NOTE,
    ID,
    SUBSCRIPTIONS,
    SUBSCRIBE,
    UNSUBSCRIBE,
    SAVE_NOTE,
    RATE,
    CLEAN, FLAG, TOGGLE_THEME, SET_THEME
} from "../actions";

import {initialState} from "../initial-state";

const rootReducer = (state = initialState, action) => {
    switch(action.type) {
        case PROFILE_SAVED_NOTES:
            return {
                ...state,
                profile: {
                    ...state.profile,
                    saved_notes: action.saved_notes,
                    saved_notes_page: action.saved_notes_page,
                }
            }
        case SUBSCRIBE:
            return {
                ...state,
                profile: {
                    ...state.profile,
                    sub_number: state.profile.sub_number + 1,
                    is_sub: true
                }
            }
        case UNSUBSCRIBE:
            return {
                ...state,
                profile: {
                    ...state.profile,
                    sub_number: state.profile.sub_number - 1,
                    is_sub: false
                }
            }
        case PROFILE:
            return {
                ...state,
                profile: {
                    ...state.profile,
                    name: action.name,
                    rating: action.rating,
                    sub_number: action.sub_number,
                    publications_number: action.publications_number,
                    is_sub: action.is_sub
                }
            }
        case CLEAN:
            return {
                ...state,
                avatar: null,
                profile: {
                    name: null,
                    rating: null,
                    sub_number: null,
                    publications_number: null,
                    created_notes: null,
                    saved_notes: null,
                    subscriptions: null,
                    created_notes_page: null,
                    saved_notes_page: null,
                    is_sub: null,
                },
                note: {
                    id: null,
                    name: null,
                    description: null,
                    owner_name: null,
                    owner_id: null,
                    is_saved: null,
                    url: null,
                    files: null,
                    rating: null,
                    stars: null,
                    image: null,
                    date: null,
                }
            }
        case SUBSCRIPTIONS:
            return {
                ...state,
                profile: {
                    ...state.profile,
                    subscriptions: action.subscriptions,
                }
            }
        case ADD_NOTE:
            return {
                ...state,
                profile: {
                    ...state.profile,
                    publications_number: state.profile.publications_number + 1
                }
            }
        case ID:
            return {
                ...state,
                id: action.id,
            }
        case CURRENT_NOTE:
            const date = action.date.toString();
            return {
                ...state,
                note: {
                    id: action.id,
                    name: action.name,
                    description: action.description,
                    owner_name: action.owner_name,
                    owner_id: action.owner_id,
                    is_saved: action.is_saved,
                    image: action.image,
                    url: action.url,
                    rating: action.rating,
                    stars: action.stars,
                    date: date.slice(8,10) + '.' + date.slice(5,7) + '.' + date.slice(0, 4)
                }
            }
        case SAVE_NOTE:
            return {
                ...state,
                note: {
                    ...state.note,
                    is_saved: !state.note.is_saved,
                }
            }
        case RATE:
            return {
                ...state,
                note: {
                    ...state.note,
                    stars: action.stars,
                }
            }
        case PROFILE_CREATED_NOTES:
            return {
                ...state,
                profile: {
                   ...state.profile,
                    created_notes: action.created_notes,
                    created_notes_page: action.created_notes_page,
                }
            }
        case AVATAR:
            return {
                ...state,
                avatar: action.avatar
            }
        case SEARCH:
            return {
                ...state,
                search: {
                    notes: action.notes,
                    page: action.page,
                    words: action.words,
                    flag: action.flag
                }
            }
        case TOGGLE_THEME:
            return {
                ...state,
                theme: state.theme === "dark" ? "light" : "dark"
            }
        case SET_THEME:
            return {
                ...state,
                theme: action.theme
            }
        case FLAG:
            return {
                ...state,
                search: {
                    ...state.search,
                    flag: action.flag
                }
            }
        default: {
            return {
                ...state
            }
        }

    }
}
export default rootReducer;