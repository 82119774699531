import stylesLight from './header.module.css';
import stylesDark from './header-dark.module.css'
import logoSrc from "./../../images/logo.png"
import logoSearch from "./../../images/search.png"
import logoAvatar from "./../../images/avatar.png"
import darkLogoAvatar from "./../../images/profile-dark.png";
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import moonIcon from "./../../images/moon.png"
import darkSearchIcon from "./../../images/search-dark.png"
import sunIcon from "./../../images/sun.png"
import {TOGGLE_THEME} from "../../services/actions";
import {useEffect, useState} from "react";
function Header() {
    const dispatch = useDispatch();
    const [rotate, setRotate] = useState(false);
    const theme = useSelector(state => {
        return state.theme
    })
    const [styles, setStyles] = useState(theme === 'light' ? stylesLight : stylesDark)
    useEffect(() => {
        setStyles(theme === 'light' ? stylesLight : stylesDark)
    }, [theme]);
    const profileId = useSelector(state => {
        return state.id
    })
    const toggleTheme = () => {
        setRotate(true);
        setTimeout(() => {
            dispatch({
                type: TOGGLE_THEME
            })
        }, 150);
        setTimeout(() => {
            setRotate(false)
        }, 300);
        localStorage.setItem("theme", theme === "dark" ? "light" : "dark")
    }
    return (
        <header className={styles.header}>
            <Link to={"/"} className={styles.container}>
                <button className={styles.button_l}>
                    <img src={logoSrc} alt={"Logo"} className={styles.logo}/>
                </button>
                <h1 className={styles.title}>
                    NoteShare
                </h1>
            </Link>
            <nav className={styles.header__nav}>
                <button onClick={toggleTheme} className={styles.themeButton}>
                    <img className={`${styles.themeIcon} ${rotate ? styles.themeIconRotate : ""}`}
                         src={theme === "dark" ? moonIcon : sunIcon} alt={"Тема"}/>
                </button>
                <Link to={"/search"} className={styles.button_m}>
                    <img src={theme === "light" ? logoSearch : darkSearchIcon} alt={"Поиск"} className={styles.image}/>
                </Link>
                {
                    profileId !== null &&
                    <Link to={`/profile/${profileId}`} className={styles.button_m}>
                        <img src={theme === 'dark' ? darkLogoAvatar : logoAvatar} alt={"Avatar"} className={styles.image}/>
                    </Link>
                }
            </nav>
        </header>
    );
}

export default Header;
