import {checkResponse} from "../utils";
import {authServiceBaseUrl} from "../consts";

function getId (token) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload).sub;
}
export function signIn(email, password) {
    return fetch(`${authServiceBaseUrl}/sign-in`, {
            method: 'POST',
            headers: {
                'Content-Type': "application/json"
            },
            body: JSON.stringify({
                email: email,
                password: password
            })
        })
            .then((res) => checkResponse(res))
            .then((res) => {
                localStorage.setItem("token", res.payload.access_token);
                localStorage.setItem("user", getId(res.payload.access_token));
            })
}
