import stylesLight from "./filters-bar.module.css";
import stylesDark from "./filter-bar-dark.module.css";
import Filter from "../filter/filter";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getSearchNotes} from "../../services/actions/get-search-notes";
import {getDefaultNotes} from "../../services/actions/get-default-notes";
import {getRatingNotes} from "../../services/actions/get-rating-notes";
import {CLEAN} from "../../services/actions";
function FiltersBar() {
    const theme = useSelector(state => {
        return state.theme
    })
    const [styles, setStyles] = useState(theme === 'light' ? stylesLight : stylesDark)
    useEffect(() => {
        setStyles(theme === 'light' ? stylesLight : stylesDark)
    }, [theme]);
    const [subject, setSubject] = useState('');
    const [uni, setUni] = useState('');
    const [faculty, setFaculty] = useState('');
    const notes = useSelector(state => {
        return state.search
    });
    const [course, setCourse] = useState('');
    const dispatch = useDispatch();
    const makeSearch = (event, page) => {
        event.preventDefault();
        dispatch({
            type: CLEAN
        })
        dispatch(getSearchNotes(page, (notes.words === null ? '' : notes.words) + ' ' + subject + ' ' + uni + ' ' + faculty + ' ' + course, notes.flag));
    }
    const getPopularNotes = ()  => {
        dispatch({
            type: CLEAN
        })
        dispatch(getDefaultNotes(1, notes.words, !notes.flag))
    }
    const getRateNotes = ()  => {
        dispatch({
            type: CLEAN
        })
        dispatch(getRatingNotes(1, notes.words, !notes.flag))
    }
    useEffect(() => {
        setSubject('');
        setUni('');
        setFaculty('');
    }, [notes.flag]);
    return(
        <section className={styles.main}>
            <Filter title={"Курс"}>
                <fieldset className={styles.inputs}>
                    <label onClick={() => setCourse("1 курс")} className={styles.label}>
                        <input className={styles.input__radio} type="radio" name="radio"/>
                        1 курс, бакалавриат
                    </label>
                    <label onClick={() => setCourse("2 курс")} className={styles.label}>
                        <input className={styles.input__radio} type="radio" name="radio"/>
                        2 курс, бакалавриат
                    </label>
                    <label onClick={() => setCourse("3 курс")} className={styles.label}>
                        <input className={styles.input__radio} type="radio" name="radio"/>
                        3 курс, бакалавриат
                    </label>
                    <label onClick={() => setCourse("4 курс")} className={styles.label}>
                        <input className={styles.input__radio} type="radio" name="radio"/>
                        4 курс, бакалавриат
                    </label>
                    <label onClick={() => setCourse("магистратура")} className={styles.label}>
                        <input className={styles.input__radio} type="radio" name="radio"/>
                        магистратура
                    </label>
                </fieldset>
            </Filter>
            <Filter title={"Предмет"}>
                <input value={subject} onChange={(event) => setSubject(event.target.value)} className={styles.input} placeholder={"Название предмета"} type={'text'}/>
            </Filter>
            <Filter title={"Вуз"}>
                <input value={uni} onChange={(event) => setUni(event.target.value)} className={styles.input} placeholder={"Название вуза"} type={'text'}/>
            </Filter>
            <Filter title={"Факультет"}>
                <input value={faculty} onChange={(event) => setFaculty(event.target.value)} className={styles.input} placeholder={"Название факультета"} type={'text'}/>
            </Filter>
            <button onClick={(event) => makeSearch(event, 1)} className={styles.button}>
                Фильтр
            </button>
            <Filter title={"Сортировать по"}>
                {notes.flag ?
                    <div className={styles.buttons}>
                        <button disabled className={styles.button__active}>
                            Популярности
                        </button>
                        <button onClick={getRateNotes} className={styles.button__inactive}>
                            Рейтингу
                        </button>
                    </div> :
                    <div className={styles.buttons}>
                        <button onClick={getPopularNotes} className={styles.button__inactive}>
                            Популярности
                        </button>
                        <button disabled className={styles.button__active}>
                            Рейтингу
                        </button>
                    </div>
                }
            </Filter>
        </section>
    )
}

export default FiltersBar;