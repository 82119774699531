import styles from "./search.module.css";
import SearchBar from "../search-bar/search-bar";
import FiltersBar from "../filters-bar/filters-bar";
import NoteCard from "../note-card/note-card";
import {useDispatch, useSelector} from "react-redux";
import pagination from "../../images/pagination.png";
import paginationDark from "../../images/pagination-dark.png";
import {useEffect} from "react";
import {getDefaultNotes} from "../../services/actions/get-default-notes";
import {getSearchNotes} from "../../services/actions/get-search-notes";
import {getRatingNotes} from "../../services/actions/get-rating-notes";
import { jelly } from 'ldrs';
import {CLEAN} from "../../services/actions";
jelly.register()
function Search() {
    const theme = useSelector(state => {
        return state.theme
    })
    const notes = useSelector(state => {
        return state.search
    });
    const dispatch = useDispatch();
    useEffect(()=>{
        dispatch(getDefaultNotes(1, notes.words, notes.flag));
    },[dispatch]);
    const makeSearch = (event, page) => {
        event.preventDefault();
        if ((notes.words === null || notes.words.length === 0) && notes.flag) {
            dispatch({
                type: CLEAN
            })
            dispatch(getDefaultNotes(page, notes.words, notes.flag))
            return
        }
        if ((notes.words === null || notes.words.length === 0) && !notes.flag) {
            dispatch({
                type: CLEAN
            })
            dispatch(getRatingNotes(page, notes.words, notes.flag))
            return
        }
        dispatch({
            type: CLEAN
        })
        dispatch(getSearchNotes(page, notes.words, notes.flag));
    }
    return(
        <section className={styles.search}>
            <SearchBar />
            <div className={styles.container}>
                <FiltersBar />
                <div className={styles.notes}>
                    {notes.notes === null ?
                        (<l-jelly
                            size="40"
                            speed="0.9"
                            color="#99C1F1"
                        ></l-jelly>) :
                        (<div className={styles.cards__list}>
                            {notes.notes.length > 0 && notes.notes.map((note) => (
                                <NoteCard isPublic={note.public} key={note.id} flag={true} {...note}/>))}
                        </div>)
                    }
                    <div className={styles.pagination__buttons}>
                        {notes.notes !== null && notes.page !== 1 &&
                            <button onClick={(event) => makeSearch(event, notes.page - 1)}
                                    className={styles.pagination__button__left}>
                                <img alt={"Предыдущие 20 конспектов"} className={styles.pagination__image}
                                     src={theme === 'light' ? pagination : paginationDark}/>
                            </button>
                        }
                        {notes.notes !== null &&
                            notes.notes.length === 20 && <button onClick={(event) => makeSearch(event, notes.page + 1)}  className={styles.pagination__button__right}>
                                <img alt={"Следующие 20 конспектов"} className={styles.pagination__image} src={theme === 'light' ? pagination : paginationDark}/>
                            </button>
                        }
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Search;
