import stylesLight from "./profile-card.module.css";
import stylesDark from "./profile-card-dark.module.css";
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";
import {useEffect, useState} from "react";
function ProfileCard({username, id}) {
    const theme = useSelector(state => {
        return state.theme
    })
    const [styles, setStyles] = useState(theme === 'light' ? stylesLight : stylesDark)
    useEffect(() => {
        setStyles(theme === 'light' ? stylesLight : stylesDark)
    }, [theme]);
    return(
        <section className={styles.card}>
            <Link to={`/profile/${id}`} className={styles.image__button} >
                <img className={styles.image} alt={username} src={"https://api.dicebear.com/8.x/thumbs/svg?seed=" + id}/>
            </Link>
            <div className={styles.text}>
                <p className={styles.name}>
                    {username}
                </p>
            </div>
        </section>
    )
}

export default ProfileCard;
