import stylesLight from "./search-bar.module.css";
import stylesDark from "./search-bar-dark.module.css"
import {useEffect, useState} from "react";
import searchIcon from "../../images/search-icon-blue.png"
import darkSearchIcon from "../../images/dark-search-icon.png";
import {useDispatch, useSelector} from "react-redux";
import {getSearchNotes} from "../../services/actions/get-search-notes";
import {getDefaultNotes} from "../../services/actions/get-default-notes";
import {getRatingNotes} from "../../services/actions/get-rating-notes";
import {CLEAN} from "../../services/actions";
const list = ["для учебы", "МФТИ линейная алгебра", "ИТМО С++", "МГУ атомная физика", "ВШЭ экономика"]
function SearchBar() {
    const theme = useSelector(state => state.theme);
    const [styles, setStyles] = useState(theme === 'light' ? stylesLight : stylesDark)
    useEffect(() => {
        setStyles(theme === 'light' ? stylesLight : stylesDark)
    }, [theme]);
    const [text, setText] = useState('');
    const [index, setIndex] = useState(0);
    const text0 = (index % 5 === 0 ? styles.slider__open : styles.slider__close);
    const text1 = (index % 5 === 1 ? styles.slider__open : styles.slider__close);
    const text2 = (index % 5 === 2 ? styles.slider__open : styles.slider__close);
    const text3 = (index % 5 === 3 ? styles.slider__open : styles.slider__close);
    const text4 = (index % 5 === 4 ? styles.slider__open : styles.slider__close);
    let interval;
    const dispatch = useDispatch();
    const textAnimation = () => {
        setIndex(index => (index + 1) % 5)
    }
    const notes = useSelector(state => {
        return state.search
    });
    useEffect(() => {
        interval = setInterval(() => {
            textAnimation()
        }, 3000)
    }, [textAnimation]);
    const makeSearch = (event, page) => {
        event.preventDefault();
        if (text.length === 0 && notes.flag) {
            dispatch({
                type: CLEAN
            })
            dispatch(getDefaultNotes(1, '', notes.flag))
            return;
        }
        if (text.length === 0 && !notes.flag) {
            dispatch({
                type: CLEAN
            })
            dispatch(getRatingNotes(1, '', notes.flag))
            return;
        }
        dispatch({
            type: CLEAN
        })
        dispatch(getSearchNotes(page, text, notes.flag));
    }
    useEffect(() => {
        setText('')
    }, [notes.flag]);
    return(
        <div className={styles.search__bar}>
            <form onSubmit={(event) => makeSearch(event, 1)} className={styles.input__box}>
                <input value={text} type={"text"} className={styles.input} placeholder={"Конспекты"}
                       onChange={(event) => setText(event.target.value)}/>
                <div className={styles.placeholder}>
                    <div className={styles.slider__box}>
                        <div className={text0}>
                            <p className={styles.text}>
                                {list[0]}
                            </p>
                        </div>
                        <div className={text1}>
                            <p className={styles.text}>
                                {list[1]}
                            </p>
                        </div>
                        <div className={text2}>
                            <p className={styles.text}>
                                {list[2]}
                            </p>
                        </div>
                        <div className={text3}>
                            <p className={styles.text}>
                                {list[3]}
                            </p>
                        </div>
                        <div className={text4}>
                            <p className={styles.text}>
                                {list[4]}
                            </p>
                        </div>
                    </div>
                </div>
                <button type="submit" className={styles.button}>
                    <img  alt={"Кнопка поиска"} src={theme === "dark" ? darkSearchIcon : searchIcon} className={styles.search__icon}/>
                </button>
            </form>
        </div>
    )
}

export default SearchBar;
