export const PROFILE_CREATED_NOTES = "PROFILE_CREATED_NOTES";
export const PROFILE_SAVED_NOTES = "PROFILE_SAVED_NOTES";
export const SUBSCRIPTIONS = "SUBSCRIPTIONS";
export const SUBSCRIBE = "SUBSCRIBE";
export const UNSUBSCRIBE = "UNSUBSCRIBE";
export const ADD_NOTE = "ADD_NOTE";
export const DELETE_NOTE = "DELETE_NOTE";
export const SEARCH = "SEARCH";
export const PROFILE = "PROFILE";
export const CURRENT_NOTE = "CURRENT_NOTE";
export const AVATAR = "AVATAR";
export const ID = "ID";
export const SAVE_NOTE = "SAVE_NOTE";
export const RATE = "RATE";
export const CLEAN = "CLEAN";
export const FLAG = "FLAG";
export const SET_THEME = "SET_THEME";
export const TOGGLE_THEME = "TOGGLE_THEME";