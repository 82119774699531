import {Bounce, toast} from "react-toastify";
import {fileUploadServiceBaseUrl} from "../consts";
export function postNewNote(formData, type) {
    return fetch(`${fileUploadServiceBaseUrl}/upload/${type}`, {
            method: 'POST',
            headers: {
                'Authorization': `${localStorage.getItem("token")}`
            },
            body: formData
        })
            .then(res => res.json())
            .catch((error) => {
                console.log(error)
                toast.error(error, {
                    position: "top-right",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce,
                });
            })
}
