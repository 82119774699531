import styles from "./main.module.css"
import Entry from "../entry/entry";
import { Routes, Route } from "react-router-dom";
import SignUp from "../signup/signup";
import Profile from "../profile/profile";
import Search from "../search/search";
import AddNote from "../add-note/add-note";
import DetailedNote from "../detailed-note/detailed-note";
import Landing from "../landing/landing";
import {ToastContainer} from "react-toastify";
import {useSelector} from "react-redux";
function Main(){
    const theme = useSelector(state => {
        return state.theme
    })
    return(
        <main className={theme === "dark" ? styles.mainDark : styles.main}>
            <ToastContainer />
            <Routes>
                <Route path={"/"} element={<Landing></Landing>}>
                </Route>
                <Route path={"/signup"} element={<SignUp></SignUp>}>
                </Route>
                <Route path={"/entry"} element={<Entry></Entry>}>
                </Route>
                <Route path={"/profile/:id"} element={<Profile />}>
                </Route>
                <Route path={"/search"} element={<Search />}>
                </Route>
                <Route path={"/add-note"} element={<AddNote />}>
                </Route>
                <Route path={"/detailed-note/:id"} element={<DetailedNote />}>
                </Route>
            </Routes>
        </main>
    )
}
export default Main;