import {SUBSCRIPTIONS} from "./index";
import {checkResponse} from "../utils";
import {fileServiceBaseUrl} from "../consts";
export function getSubscriptions(id) {
    return function (dispatch) {
        fetch(`${fileServiceBaseUrl}/profile/${id}/subscribed`, {
            method: 'GET',
            headers: {
                'Content-Type': "application/json",
                'Authorization': `${localStorage.getItem("token")}`
            }
        })
            .then((res) => checkResponse(res))
            .then((res) => {
                dispatch({
                    type: SUBSCRIPTIONS,
                    subscriptions: res.payload.users
                })
            })
            .catch(console.error)
    }
}
