import React from 'react';
import ReactDOM from 'react-dom/client';
import "./normalize.css";
import "./fonts.css"
import store from "./services/store";
import { Provider } from 'react-redux';
import App from './components/app/app';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <Provider store={store}>
          <App />
      </Provider>
  </React.StrictMode>
);

