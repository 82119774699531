import {Bounce, toast} from "react-toastify";

export function checkResponse(res) {
    if (res.ok) {
        console.log(res)
        return res.json();
    }
    if (res.status === 403) {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        toast.error(`Ошибка ${res.status}, авторизуйтесь, пожалуйста, заново`, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
        });
    }
    return Promise.reject(`Ошибка ${res.status}`);
}
