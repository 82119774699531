import styles from './app.module.css';
import Main from "../main/main";
import Header from "../header/header";
import 'bootstrap/dist/css/bootstrap.min.css';
import {BrowserRouter as Router, useLocation} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import 'react-toastify/dist/ReactToastify.css';
import {CLEAN, ID, SET_THEME} from "../../services/actions";
function CleanState({ children }) {
    const location = useLocation();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch({
            type: CLEAN
        });
        dispatch({
            type: ID,
            id: localStorage.getItem("user")
        })
    },[location.pathname])

    return <>{children}</>;
}
function App() {
    const theme = useSelector(state => {
        return state.theme
    })
    const dispatch = useDispatch();
    useEffect(() => {
        if (localStorage.getItem("theme")) {
            dispatch({
                type: SET_THEME,
                theme: localStorage.getItem("theme")
            })
        }
        else {
            localStorage.setItem("theme", theme);
        }
    }, [])
  return (
          <Router>
              <CleanState>
                  <div className={styles.app}>
                      <Header/>
                      <div>
                      </div>
                      <Main/>
                  </div>
              </CleanState>
          </Router>
  );
}

export default App;
