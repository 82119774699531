import stylesLight from "./add-note.module.css";
import stylesDark from "./add-note-dark.module.css"
import addImageIcon from "./../../images/add-image.png"
import {useEffect, useState} from "react";
import { useDropzone } from 'react-dropzone';
import deleteNoteIcon from "./../../images/delete-note.png";
import {Bounce, toast} from "react-toastify";
import {postNewNote} from "../../services/actions/post-new-note";
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";

function AddNote() {
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [uni, setUni] = useState('');
    const [faculty, setFaculty] = useState('');
    const [words, setWords] = useState('');
    const [privacy, setPrivacy] = useState(false);
    const [disableSubmitButton, setDisableSubmitButton] = useState(false)
    const { getRootProps, getInputProps } = useDropzone({
        onDrop: (acceptedFiles) => {
            setUploadedFiles([...uploadedFiles, acceptedFiles[0]]);
        },
    });
    const theme = useSelector(state => {
        return state.theme
    })
    const [styles, setStyles] = useState(theme === 'light' ? stylesLight : stylesDark)
    useEffect(() => {
        setStyles(theme === 'light' ? stylesLight : stylesDark)
    }, [theme]);
    const navigate = useNavigate();
    const formNote = (evt) => {
        evt.preventDefault();
        setDisableSubmitButton(true)
        if (uploadedFiles.length === 0) {
            toast.error('Добавьте, пожалуйста, файлы', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
            });
            return;
        }
        const formData = new FormData();
        for (let i = 0; i < uploadedFiles.length; i++) {
            if (uploadedFiles[i].type === 'application/pdf' && uploadedFiles.length > 1) {
                toast.error('PDF файл может быть только один', {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce,
                });
                return;
            }
            if (!['application/pdf', 'image/png', 'image/jpg', 'image/jpeg'].includes(uploadedFiles[i].type)) {
                toast.error('Неправильный формат файла', {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce,
                });
                return;
            }
            formData.append('files', uploadedFiles[i])
        }
        formData.append('fileInfo', new Blob([JSON.stringify({"name": name, "description": description, "keywords": `${uni} ${faculty} ${words}`, "public": privacy ? 0 : 1})], {
            type: "application/json"
        }));
        toast.info ('Создание конспекта...', {
            position: "top-right",
            autoClose: false,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
        });
        postNewNote(formData, uploadedFiles[0].type === 'application/pdf' ? "pdf" : "image").then(() => {
            toast.dismiss()
            toast.success('Конспект успешно создан', {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
            });
        })
            .then(() => {
                navigate(-1);
                setName('');
                setDescription('');
                setUni('');
                setFaculty('');
                setWords('');
                setDisableSubmitButton(false)
            })
    }
    const handleRemoveItem = (file) => {
        setUploadedFiles(uploadedFiles => uploadedFiles.filter(item => item !== file));
    };
    return(
        <section className={styles.main}>
            <div className={styles.add__images}>
                <p className={styles.title}>Добавьте фотографии конспектов</p>
                <div className={styles.box}>
                    <div className={styles.drop__area}>
                        <input type="file" multiple="multiple" {...getInputProps()} />
                        <img {...getRootProps()} className={styles.image} src={addImageIcon} alt={"Добавление фото"}/>
                        <p className={styles.box__title}>Выберите или перетащите файлы</p>
                        <p className={styles.box__subtitle}>Один PDF или несколько PNG/JPG/JPEG</p>
                        <div className={styles.notes}>
                            {uploadedFiles.map((file) => (
                                <div className={styles.note} key={file.name}>
                                    <p className={styles.notes__text}>{file.name}</p>
                                    <button onClick={() => handleRemoveItem(file)} className={styles.delete__button}>
                                        <img className={styles.delete__image} src={deleteNoteIcon} alt={"Удаление конспекта"}/>
                                    </button>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <form onSubmit={(event) => (formNote(event))} className={styles.add__note}>
                <p className={styles.title}>
                    Добавьте конспект
                </p>
                <label className={styles.box__title}>
                    Название
                </label>
                <input value={name} required minLength="5" maxLength="70" className={styles.input} onChange={(event) => (setName(event.target.value))} />
                <label className={styles.box__title}>
                    Описание
                </label>
                <textarea value={description} onChange={(event) => (setDescription(event.target.value))} className={styles.text__area} cols="30" rows="5"/>
                <div className={styles.inputs__container}>
                    <div className={styles.input__container}>
                        <label className={styles.box__title}>
                            Вуз
                        </label>
                        <input value={uni} onChange={(event) => (setUni(event.target.value))} maxLength="40" className={styles.input}/>
                    </div>
                    <div className={styles.input__container}>
                        <label className={styles.box__title}>
                            Факультет
                        </label>
                        <input value={faculty} onChange={(event) => (setFaculty(event.target.value))} maxLength="40" className={styles.input}/>
                    </div>
                </div>
                <label className={styles.box__title}>
                    Ключевые слова
                </label>
                <input value={words} onChange={(event) => (setWords(event.target.value))}  minLength="5" maxLength="40" className={styles.input}/>
                <div className={styles.check__container}>
                    <p className={styles.box__title}>
                        Сделать приватным
                    </p>
                    <div onChange={() => (setPrivacy(!privacy))} >
                        <input id="check" type="checkbox" className={styles.checkbox}/>
                        <label htmlFor="check" className={styles.check__trail}>
                            <span className={styles.span}></span>
                        </label>
                    </div>
                </div>
                <button className={styles.submit__button} disabled={disableSubmitButton} type={"submit"}>Добавить</button>
            </form>
        </section>
    )
}

export default AddNote;