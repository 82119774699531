import stylesLight from "./note-card.module.css";
import stylesDark from "./note-card-dark.module.css";
import {Link} from "react-router-dom";
import lock from "./../../images/lock.png"
import lockDark from "./../../images/lock-dark.png"
import {useSelector} from "react-redux";
import {useEffect, useState} from "react";
function NoteCard({name, previewUrl, authorId, id, rating, isPublic}) {
    const theme = useSelector(state => {
        return state.theme
    })
    const [styles, setStyles] = useState(theme === 'light' ? stylesLight : stylesDark)
    useEffect(() => {
        setStyles(theme === 'light' ? stylesLight : stylesDark)
    }, [theme]);
    const profileId = useSelector(state => {
        return state.id
    })
    return(
        <section className={styles.card}>
            <Link to={`/detailed-note/${id}`} className={styles.image__button} >
                <img className={styles.image} alt={name} src={previewUrl}/>
            </Link>
            <div className={styles.text}>
            <p className={styles.name}>
                    {name}
                </p>
            </div>
            {
                (isPublic || isPublic === null) && <div className={styles.rating__container}>
                    <div className={styles.star}>
                        <span className={styles.rating}>{rating}</span>
                    </div>
                </div>
            }
            {
                String(authorId) === String(profileId) && !isPublic && isPublic !== null &&
                <img alt={"Замочек"} src={theme === 'light' ? lock : lockDark} className={styles.lock}/>
            }
        </section>
    )
}

export default NoteCard;
