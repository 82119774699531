import {checkResponse} from "../utils";
import {SUBSCRIBE} from "./index";
import {fileServiceBaseUrl} from "../consts";
export function subscribe(id) {
    return function (dispatch) {
        fetch(`${fileServiceBaseUrl}/profile/${id}/subscribe`, {
            method: 'PUT',
            headers: {
                'Content-Type': "application/json",
                'Authorization': `${localStorage.getItem("token")}`
            }
        })
            .then((res) => checkResponse(res))
            .then(() => {
                dispatch({
                    type: SUBSCRIBE
                })
            })
            .catch(console.error)
    }
}
